<template>
  <page-header-wrapper :content="'用户ID：' + this.queryParam.playerId">
    <a-card :bordered="false">
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { getPlayerPlaylogList } from '@/api/game/mai2'

export default {
  name: 'PlayerPlaylogList',
  components: {
    STable
  },
  data () {
    return {
      roleFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
        playerId: this.$route.params.playerId
      },
      loadData: parameter => {
        return getPlayerPlaylogList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: '歌曲ID',
          dataIndex: 'musicId',
          sorter: true
        },
        {
          title: '难度',
          dataIndex: 'level',
          sorter: true
        },
        {
          title: '曲目',
          dataIndex: 'trackNo',
          sorter: true
        },
        {
          title: '达成率',
          dataIndex: 'achievement',
          sorter: true
        },
        {
          title: '成绩级别',
          dataIndex: 'scoreRank',
          sorter: true
        },
        {
          title: 'DX分数',
          dataIndex: 'deluxscore',
          sorter: true
        },
        {
          title: '连击数',
          dataIndex: 'maxCombo',
          sorter: true
        },
        {
          title: 'SYNC数',
          dataIndex: 'maxSync',
          sorter: true
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
